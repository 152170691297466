export const BASE_URL = 'https://www.proxima-universe.com/Web';
export const GAME_URL = 'https://ckproxima.github.io/ProximaV2Github/';


export const MTV_NETWORK = {
    chainId: 62621,
    rpcUrl: 'https://rpc.mtv.ac',
};

export const PROX_TOKEN = '0x9029d1dEDa00e166De55406eAB2608E4efC66D7b';

export const FighterMOUNT_CONTRACT = '0x19f4730220361E90eE7B85f7cA3c3A568f289F1D';
export const BotMOUNT_CONTRACT = '';

export const TELLURIAN_FIGHTER = '0x70533ad4FaD157351294ee906cC2F09c922d213a';
export const CELESTIAL_FIGHTER = '0xD97333f247B3d343F8886320beed80bf6CCa9D77';
export const MACHINA_FIGHTER = '0xD8b9c28A7275107cc1B1BC80e578B4d888386A38';
export const TELLURIAN_BOT = '0xa3cdc82351e9c59629fea6806a7433d2d9c999dd';
export const CELESTIAL_BOT = '0x4236a1b37248d6e44500e3e7fb00bdee9278f12f';
export const MACHINA_BOT = '0x74790be6eb1195f27c50535beabc6dc0431bce47';
export const SATOSHI_BOT = '0x02b56633fc76481a0f4be3740ddeab24e86bf6a0';


