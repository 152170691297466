import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';
import { useLogLogin } from './useLogLogin';

import { BASE_URL } from '../apiConfig';

const multiVACNetwork = {
    chainId: '62621',
    chainName: 'MultiVAC Mainnet',
    nativeCurrency: {
        name: 'MultiVAC',
        symbol: 'MTV',
        decimals: 18,
    },
    rpcUrls: ['https://rpc.mtv.ac'],
    blockExplorerUrls: ['https://e.mtv.ac'],
};

export function useHandleLogin(setLoginStatus) {
    const { updateUser } = useContext(UserContext);
    const navigate = useNavigate();
    const logLogin = useLogLogin();

    const handleLogin = async () => {
        try {
            setLoginStatus('Switching network...');
            if (window.ethereum) {
                const hexChainId = `0x${parseInt(multiVACNetwork.chainId).toString(16)}`;
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: hexChainId }],
                });

                setLoginStatus('Requesting accounts...');
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const account = accounts[0];

                setLoginStatus('Checking subscription status...');
                const url = `${BASE_URL}/check_subscriber?address=${account}`;
                const response = await fetch(url);
                const data = await response.json();

                if (data.is_subscriber) {
                    setLoginStatus('Logging in...');
                    const loginSuccess = await logLogin(account);
                    if (!loginSuccess) {
                        setLoginStatus('Login failed. Please try again.');
                        return;
                    }
                    updateUser({ accounts: account });
                    setLoginStatus('Login successful!');
                    setTimeout(() => navigate('/loading'), 1000);
                } else {
                    setLoginStatus('Redirecting to subscription...');
                    setTimeout(() => navigate('/subscription'), 1000);
                }
            } else {
                setLoginStatus('MetaMask not installed!');
            }
        } catch (error) {
            console.error(error);
            if (error.code === 4902) {
                try {
                    setLoginStatus('Adding network...');
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [multiVACNetwork],
                    });
                    await handleLogin();
                } catch (addError) {
                    console.error('Failed to add the network:', addError);
                    setLoginStatus('Failed to add the network.');
                }
            } else {
                setLoginStatus('An error occurred. Please try again.');
            }
        }
    };

    return handleLogin;
}
