import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './UserContext';
import App from './App';


import LoadingPage from './LoadingPage';
import MainPage from './MainPage/MainPage';
import Subscription from './Subscription';



import { Web3Provider } from './Hooks/Web3Context';
import StarryBackground from './StarryBackground';

const container = document.getElementById('root');
const root = createRoot(container);


root.render(
    <React.StrictMode>
        <Web3Provider> 
            <UserProvider>
                <Router>
                    <StarryBackground />
                    <Routes>
                        <Route path="/" element={<App />} />
                        <Route path="/loading" element={<LoadingPage />} />
                        <Route path="/mainpage/*" element={<MainPage />} />
                        <Route path="/subscription" element={<Subscription />} />

                    </Routes>
                </Router>
            </UserProvider>
        </Web3Provider>
    </React.StrictMode>
);