import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import UserContext from '../UserContext';
import Sidebar from './SideBar';
import GamePage from './GamePage/GamePage';
import CircSupply from './CircSupply/CircSupply';
import AccountPage from './AccountPage/AccountPage';
import HangerPage from './Hanger/HangerPage';
import AssetPage from './ProximaAssets/ProximaAssets';
import AdminPage from './AdminPage'
import './MainPage.css';


import { BASE_URL } from '../apiConfig';

function MainPage() {
    const { userInfo, updateUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [failedAttempts, setFailedAttempts] = useState(0); // State to track failed attempts

    useEffect(() => {
        if (!userInfo.accounts || userInfo.accounts.length === 0) {
            console.log('Redirecting to login, no accounts info.');
            navigate('/');
        }
    }, [userInfo, navigate]);

    useEffect(() => {
        const verifyKeyInterval = setInterval(() => {
            if (userInfo.LoginKey && userInfo.accounts) {
                const url = `${BASE_URL}/verify_login_key`;  // Update to use verify endpoint
                const requestData = {
                    account: userInfo.accounts,
                    token: userInfo.LoginKey
                };

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestData)
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            throw new Error('Failed to verify key');
                        }
                    })
                    .then(data => {
                        if (data.valid) {
                            console.log('Token is valid:', data);
                            setFailedAttempts(0); // Reset failed attempts on success
                        } else {
                            console.log('Token is invalid or expired');
                            // Handle invalid token here, perhaps request a new key
                            setFailedAttempts(failed => failed + 1);
                        }
                    })
                    .catch(error => {
                        console.error('Failed to verify key:', error);
                        setFailedAttempts(failed => failed + 1); // Increment failed attempts on error
                    });
            } else {
                console.log('Key or accounts missing', userInfo);
            }
        }, 3600000); // Check every 15 seconds

        return () => {
            clearInterval(verifyKeyInterval); // Cleanup interval on component unmount
        };
    }, [userInfo, updateUser]);

    useEffect(() => {
        if (failedAttempts >= 1) {
            console.log('Redirecting token expired or is unavailable.');
            navigate('/'); // Redirect to login page
        }
    }, [failedAttempts, navigate]); // Depend on failedAttempts and navigate to handle redirection

    return (
        <div className="main-container">
            <Sidebar />
            <div className="content-main">
                <Routes>
                    <Route path="/game" element={<GamePage />} />
                    <Route path="/circsupply" element={<CircSupply />} />
                    <Route path="/accountpage" element={<AccountPage />} />
                    <Route path="/hangerpage" element={<HangerPage />} />
                    <Route path="/assetpage" element={<AssetPage />} />
                    <Route path="/admin" element={<AdminPage />} />
                </Routes>
            </div>
        </div>
    );
}

export default MainPage;
