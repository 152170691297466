import React, { useEffect, useState, useContext } from 'react';
import { BASE_URL } from '../../../apiConfig';

import './FightersDetailsPage.css';

const DetailsPage = ({ fighter }) => {

    const [imageSrc, setImageSrc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [fighterStats, setFighterStats] = useState([]);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const requestBody = {
                    wallet_address: fighter.wallet_address,
                    collection_name: fighter.collection_name,
                    token: fighter.token,
                    nft_id: fighter.token_id,
                };

                const response = await fetch(`${BASE_URL}/get_image`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to fetch image');
                }

                const blob = await response.blob();
                const imageUrl = URL.createObjectURL(blob);
                setImageSrc(imageUrl);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchImage();
    }, [fighter]);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const requestBody = {
                    wallet_address: fighter.wallet_address,
                    collection_name: fighter.collection_name,
                    token: fighter.token,
                    nft_id: fighter.token_id,
                };

                const response = await fetch(`${BASE_URL}/get_nft_stats`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to fetch stats');
                }

                const data = await response.json(); // Fetch the full JSON response
                const stats = data.mount_data; // Extract mount_data from the response

                if (!stats) {
                    throw new Error('No stats found in the response');
                }

                // Map stats to a displayable format
                const mappedStats = [
                    { name: "Movement Speed Up", value: stats.nft_up_movement },
                    { name: "Movement Speed Down", value: stats.nft_down_movement },
                    { name: "Movement Speed Forward", value: stats.nft_forward_movement },
                    { name: "Movement Speed Back", value: stats.nft_back_movement },
                    { name: "Damage", value: stats.nft_damage_basic },
                    { name: "Armor", value: stats.nft_armor },
                    { name: "Missile Damage", value: stats.nft_damage_missile },
                    { name: "Item Find", value: stats.nft_item_find },
                ];

                setFighterStats(mappedStats);
            } catch (err) {
                console.error('Error fetching stats:', err);
                setError(err.message);
            }
        };

        fetchStats();
    }, [fighter]);

    return (
        <div className="details-page">
            <h3>Fighter ID: {fighter.token_id}</h3>
            <div className="details-image-container">
                {loading ? (
                    <p>Loading image...</p>
                ) : error ? (
                    <p className="error-message">Error: {error}</p>
                ) : (
                    <img
                        src={imageSrc}
                        alt={`NFT for Fighter ID ${fighter.token_id}`}
                        className="details-image"
                    />
                )}
            </div>

            <div className="details-stats">
                {fighterStats.map((stat, index) => (
                    <div key={index} className="stat-container">
                        <span className="stat-name">{stat.name}</span>
                        <span className="stat-value">{stat.value}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DetailsPage;
