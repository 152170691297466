import React, { useRef, useEffect, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import './GamePage.css';
import { GAME_URL } from '../../apiConfig';
import UserContext from '../../UserContext';

const LOAD_LOCAL_WEBGL = false; // Set this to false to load from GitHub

function GamePage() {
    const { userInfo } = useContext(UserContext); // Access userInfo
    const gameContainerRef = useRef(null);
    const iframeRef = useRef(null);
    const [gameSrc, setGameSrc] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        console.log('userInfo:', userInfo); // Debug: Check the userInfo context
        if (userInfo && userInfo.accounts) {
            console.log('Wallet Address:', userInfo.accounts); // Confirm wallet address
            const walletAddress = userInfo.accounts; // Use directly, as it's a string

            // Dynamic query parameter separator
            const separator = GAME_URL.includes('?') ? '&' : '?';
            const newGameSrc = LOAD_LOCAL_WEBGL
                ? `/gamebuild/index.html?wallet=${walletAddress}&v=${new Date().getTime()}`
                : `${GAME_URL}${separator}wallet=${walletAddress}&v=${new Date().getTime()}`;

            console.log('Final iframe src:', newGameSrc); // Log the final iframe URL for debugging

            setGameSrc(newGameSrc);
            setIsLoading(false);
        }
    }, [userInfo]);

    useEffect(() => {
        const handleResize = () => {
            if (gameContainerRef.current) {
                gameContainerRef.current.style.width = `${window.innerWidth}px`;
                gameContainerRef.current.style.height = `${window.innerHeight}px`;
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isLoading) {
        return <div>Loading game...</div>;
    }

    return (
        <div className="game-page-container" ref={gameContainerRef}>
            <Helmet>
                <title>Game Page</title>
            </Helmet>
            <iframe
                ref={iframeRef}
                title="Proxima Game"
                src={gameSrc} // Dynamically set game source URL
                frameBorder="0"
                allowFullScreen
                className="game-iframe"
            />
        </div>
    );
}

export default GamePage;