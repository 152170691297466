import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { useHandleLogin } from './Hooks/handleLogin';
import gearImage from './Images/gear.png';
import logoImage from './headLogo.png';

function App() {
    const [loginStatus, setLoginStatus] = useState('Login'); // State for button text
    const handleLogin = useHandleLogin(setLoginStatus);
    const navigate = useNavigate();

    const handleSubscription = () => navigate('/subscription');

    return (
        <div className="App-front">
            <div className="mainsidebar">
                <div className="logo-container">
                    <img src={gearImage} alt="Rotating Gear" className="rotating-gear" />
                    <img src={logoImage} alt="Proxima Universe Logo" className="logo" />
                </div>
                <button className="menu-button-login" onClick={handleLogin}>
                    {loginStatus}
                </button>
                <hr className="app-divider" />
                {/* <button className="menu-buttonSubsciption" onClick={handleSubscription}>Subscription</button> */}
                <button className="menu-button2">Proxima MarketPlace</button>
                <hr className="app-divider" />
                <button className="menu-button2">Buy Prox</button>
                <button className="menu-button2">About Proxima</button>
                <button className="menu-button2">Link Tree</button>
            </div>
            <div className="content-area"></div>
        </div>
    );
}

export default App;
