import React, { useContext, useState, useEffect } from 'react';
import './AccountPage.css'; // Import the new CSS file
import UserContext from './../../UserContext';
import { BASE_URL } from '../../apiConfig';

const AccountPage = () => {
    const [playerData, setPlayerData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Added loading state
    const { userInfo } = useContext(UserContext);

    useEffect(() => {
        const fetchAccountData = async () => {
            if (!userInfo || !userInfo.accounts) {
                setError('No account address found.');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(`${BASE_URL}/get_account`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        account: userInfo.accounts,
                    }),
                });

                if (!response.ok) {
                    throw new Error(`Server error: ${response.statusText}`);
                }

                const result = await response.json();

                if (!result || !result.player_data) {
                    throw new Error('Invalid data received from the server.');
                }

                setPlayerData(result.player_data);
            } catch (error) {
                setError(error.message || 'Failed to fetch account data.');
            } finally {
                setLoading(false);
            }
        };

        fetchAccountData();
    }, [userInfo]);

    if (loading) {
        return (
            <div className="account-container">
                <p>Loading player data...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="account-container">
                <p className="error-message">{error}</p>
            </div>
        );
    }

    if (!playerData) {
        return (
            <div className="account-container">
                <p>No player data available.</p>
            </div>
        );
    }

    return (
        <div className="account-container">
            <h1 className="account-header">Account Statistics</h1>
            <header>
                <p className="account-address"><strong>Account Address:</strong> {userInfo?.accounts}</p>
            </header>

            <section className="account-info">
                <div>
                    <p><strong>Account Level:</strong> {playerData.player_level}</p>
                    <p><strong>Account Experience:</strong> {playerData.player_exp}</p>
                    <p><strong>Games Played:</strong> {playerData.player_games_played}</p>
                    <p><strong>Levels Completed:</strong> {playerData.player_levels_completed}</p>
                    <p><strong>Deaths:</strong> {playerData.player_times_died}</p>
                    <p><strong>Career Armor Lost:</strong> {playerData.player_armor_lost}</p>
                    <p><strong>Career Health Lost:</strong> {playerData.player_health_lost}</p>
                    <p><strong>Career Kills:</strong> {playerData.player_kills}</p>
                </div>
            </section>
        </div>
    );
};

export default AccountPage;
